import Text from 'routes/text';
import { graphql } from 'gatsby';

export default Text;

export const query = graphql`
  query TextPage($slug: String!) {
    page: contentfulTemplateTextPage(slug: { eq: $slug }) {
      pageTitle
      metaDescription
      metaImage {
        ...image
      }
      titleText
      deck {
        deck
      }
      date
      body {
        childContentfulRichText {
          html
        }
      }
    }
  }
`;
