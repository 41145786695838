import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Segment from 'components/layout';
import s from './TextBody.scss';

const TextBody = ({ title, deck, date, bodyHtml }) => (
  <Segment container>
    <div className={s.textBody}>
      <div className={s.textBody__inner}>
        <h1 className={s.textBody__title}>{title}</h1>
        {deck && (
          <p className={s.textBody__deck}>{deck}</p>
        )}
        {date && (
          <p className={s.textBody__date}>{moment(date).format('MMMM Do, YYYY')}</p>
        )}
        {/* eslint-disable-next-line react/no-danger */}
        <div className={s.textBody__body} dangerouslySetInnerHTML={{ __html: bodyHtml }} />
      </div>
    </div>
  </Segment>
);

TextBody.propTypes = {
  title: PropTypes.string,
  deck: PropTypes.string,
  date: PropTypes.string,
  bodyHtml: PropTypes.string,
};

TextBody.defaultProps = {
  title: PropTypes.string,
  deck: PropTypes.string,
  date: PropTypes.string,
  bodyHtml: PropTypes.string,
};

export default TextBody;
